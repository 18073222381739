export const idTypeMap = {
    1:"Лекция",
    2:"Практика",
    3:"Семинар",
    4:"Выходной день",
    5:"Зачет",
    6:"Зачет с оценкой",
    7:"Контрольная работа",
    8:"Кураторские часы",
    9:"Лабораторная",
    10:"Совещание",
    11:"Установ.электронная лекция",
    12:"Экзамен",
    13:"Экзамен квалификационный",
    14:"Электронная лекция",
    15:"Электронное тестирование зачет",
    16:"электронное тестирование зачет с оценкой",
    17:"Электр.тестирование экзамен",
    18:"Курсовая работа",
    19:"Консультации",
    20:"Экзаменационный просмотр",
    21:"Электронная практика",
    22:"Электронная лабораторная",
    23:"Установочное практическое занятие",
    24:"Установ.лекция",
    25:"Установ. практика",
    26:"Дист.лекция",
    27:"Дист. практика",
    28:"Дист. лабораторная",
    29:"Курсовой проект"
  };